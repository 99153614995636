export const VENDOR_LISTS = {
  pentone: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744',
    vendorName: 'Pentone',
    instagram: 'pentone.id',
    imgProps: {
      maxWidth: '90px',
      margin: '-2px 0px 4px',
    },
  },
  dalang: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257',
    vendorName: 'Dalang',
    instagram: 'dalangweddingorganizer',
    imgProps: { maxWidth: '65px', margin: '-12px 0 0' },
  },
  tirtowening: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787',
    vendorName: 'Tirto Wening',
    instagram: 'tirto_wening_wo',
    imgProps: { maxWidth: '120px', margin: '-2px 0px 6px' },
  },
  samawa: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529',
    vendorName: 'SAMAWA',
    instagram: 'samawawo',
    imgProps: { maxWidth: '80px', margin: '-2px 0 6px' },
  },
  sadewa: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185',
    vendorName: 'Sadewa',
    instagram: 'vian_sadewaweddingevent',
    imgProps: { maxWidth: '85px', margin: '-4px 0 4px' },
  },
  flatter: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005',
    vendorName: 'Flatter Media Nusantara',
    instagram: 'flattermedianusantara',
    imgProps: { maxWidth: '80px', margin: '-4px 0px 6px' },
  },
  aksara: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659',
    vendorName: 'Aksara',
    instagram: 'aksara.organizer',
    imgProps: { maxWidth: '100px', margin: '-4px 0px 4px' },
  },
  dreamworks: {
    imgUrl:
      'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073',
    vendorName: 'Dreamwork’s',
    instagram: 'dreamworks_eo',
    imgProps: { maxWidth: '115px', margin: '-4px 0px 4px' },
  },
  // format for add new vendor
  // namavendor: {
  //   imgUrl: '',
  //   vendorName: '',
  //   instagram: '',
  //   imgProps: { maxWidth: '', margin: '' },
  // },
};

